import $ from "@modules/jquery";

function equalizeHeight(container) {
  let currentTallest = 0;
  let currentRowStart = 0;
  const rowDivs = new Array();
  let topPosition = 0;
  $(container).each(function () {
    const $el = $(this);
    $($el).height("auto");
    topPosition = $el.position().top;

    if (currentRowStart !== topPosition) {
      for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        rowDivs[currentDiv].height(currentTallest);
      }
      rowDivs.length = 0; // empty the array
      currentRowStart = topPosition;
      currentTallest = $el.height();
      rowDivs.push($el);
    } else {
      rowDivs.push($el);
      currentTallest =
        currentTallest < $el.height() ? $el.height() : currentTallest;
    }
    for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
      rowDivs[currentDiv].height(currentTallest);
    }
  });
}

$(document).ready(function () {
  $("form.cart").on("click", ".plus, .minus", function () {
    // Get current quantity values
    var qty = $(this).closest("form.cart").find(".qty");
    var val = parseFloat(qty.val());
    var max = parseFloat(qty.attr("max"));
    var min = parseFloat(qty.attr("min"));
    var step = parseFloat(qty.attr("step"));

    // Change the value if plus or minus
    if ($(this).is(".plus")) {
      if (max && max <= val) {
        qty.val(max);
      } else {
        qty.val(val + step);
      }
    } else {
      if (min && min >= val) {
        qty.val(min);
      } else if (val > 1) {
        qty.val(val - step);
      }
    }
  });
  // Equal Height
  const productProducts = $(".shop-products");
  const screenSize = $(window).width();
  if (screenSize > 600) {
    if (productProducts.length) {
      productProducts.each(function () {
        const title = $(this).find(".woocommerce-loop-product__title");
        equalizeHeight(title);
        console.log("yels");
      });
    }
  }
});

$(function () {
  const quantityInputHeight = $(
    ".woocommerce .input-group-field.qty"
  ).outerHeight();
  if (quantityInputHeight != null) {
    $(".woocommerce .add-to-cart-box .single_add_to_cart_button").outerHeight(
      quantityInputHeight
    );
  }
});

$(document).ready(function () {
  $(".mfp-close, #wooac-continue").click(function () {
    var url = document.location.href;
    window.history.pushState({}, "", url.split("?")[0]);
  });

  //video slider
  const swiper = document.querySelector(
    ".header-slider .swiper-container"
  )?.swiper;
  if (swiper) {
    /* GET ALL VIDEOS */
    var sliderVideos = $(".homepagina-video");
    sliderVideos.on("ended", function () {
      swiper.slideNext();
    });

    /* SWIPER API - Event will be fired after animation to other slide (next or previous) */
    swiper.on("slideChange", function () {
      console.log("slide changed");
      /* stop all videos (currentTime buggy without this loop idea) */
      sliderVideos.each(function (index) {
        this.currentTime = 0;
      });

      /* SWIPER GET CURRENT AND PREV SLIDE (AND VIDEO INSIDE) */
      var prevVideo = $(
        "[data-swiper-slide-index=" + this.previousIndex + "]"
      ).find("video");
      var currentVideo = $(
        "[data-swiper-slide-index=" + this.realIndex + "]"
      ).find("video");
      currentVideo.trigger("play");
      prevVideo.trigger("stop");
    });
  }

  const popupClosed = localStorage.getItem("Video-Clicked");
  if (popupClosed) {
    const value = JSON.parse(popupClosed).value;
    if (value === "yes") {
      $(".home .wp-block-videopopupblock-popup-player").addClass("hide");
    }
  } else {
    $(".home .wp-block-videopopupblock-popup-player").removeClass("hide");
    $(".home .wp-block-videopopupblock-popup-player").show();
  }

  //video popup cookie
  $(".modal-video-close-btn").click(function () {
    $(".home .wp-block-videopopupblock-popup-player").addClass("hide");
    var date = new Date();
    const today = new Date().getTime();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    localStorage.setItem(
      "Video-Clicked",
      JSON.stringify({
        value: "yes",
        expDate: date,
      })
    );
    const popupClosed = localStorage.getItem("Video-Clicked");

    const expDate = JSON.parse(popupClosed).expDate;
    const value = JSON.parse(popupClosed).value;
  });
});

$(function() {
  logoScrollCheck();
  $(window).on("scroll", logoScrollCheck);
});
function logoScrollCheck() {
  const screenSize = $(window).width();
  if (screenSize > 1023) {
    // $(".logo-block .mobile").hide();
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      $(".after-menu-containter").hide();
      $(".logo-block .logo-image.mobile").hide();
      $(".icon-after-menu").show();
      $(".logo-block .logo-image.light-mode:not(.mobile").hide();
      $(".logo-block .logo-image.second-logo-img").show();
    } else {
      $(".after-menu-containter").show();
      $(".icon-after-menu").hide();
      $(".logo-block .logo-image.light-mode:not(.mobile").show();
      $(".logo-block .logo-image.second-logo-img").hide();
      // $(".logo-block .logo-image.mobile").hide();
    }
  }
}

function equalColumns() {
  let screenSize = $(window).width();
  $(".equal-columns").each(function (index) {
    $(this).attr("data-equalizer", `equal-columns-${index}`);
    const wpsoupCard = $(this).find(
      ".wp-block-column .related-page-card .card"
    );
    wpsoupCard.attr("data-equalizer-watch", `equal-columns-${index}`);

    if (screenSize >= 1025) {
      $(document).foundation();
    }
  });
}
window.addEventListener("resize", equalColumns);
$(window).on("load", function () {
  equalColumns();
  window.addEventListener("resize", equalColumns);
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("add-to-cart")) {
    var url = document.location.href;
    window.history.pushState({}, "", url.split("?")[0]);
  }
});

$(window).on("resize", function () {
  equalColumns();
});

document.addEventListener("DOMContentLoaded", function () {
  const timelines = document.querySelectorAll(".timelines .timeline");
  if (timelines.length) {
    timelines.forEach((timeline) => {
      if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
        timeline.removeAttribute("data-animation");
      }

      const shouldAnimate = !(
        !timeline.getAttribute("data-animation") ||
        "on" != timeline.getAttribute("data-animation")
      );
      if (!shouldAnimate) {
        return;
      }

      const sections = timeline.querySelectorAll("section");
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("animate");
              observer.unobserve(entry.target);
            }
          });
        },
        { rootMargin: "0px 0px -150px 0px" }
      );
      sections.forEach((section) => {
        observer.observe(section);
      });
    });
  }
});
